import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Tiny React & D3 flamegraph tutorial",
  "description": "",
  "date": "2018-06-06T08:00:00.000Z",
  "published": "2018-06-06T08:00:00.000Z",
  "image": ""
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><em parentName="p">{`This is a Livecoding Recap – an almost-weekly post about interesting things discovered while livecoding. Usually shorter than 500 words. Often with pictures. Livecoding happens almost `}<strong parentName="em">{`every Sunday at 2pm PDT`}</strong>{` on multiple channels. You should `}<a parentName="em" {...{
          "href": "http://youtube.com/user/theswizec"
        }}>{`subscribe to ￼My Youtube￼ channel`}</a>{`(`}<a parentName="em" {...{
          "href": "https://www.youtube.com/user/theswizec"
        }}>{`https://www.youtube.com/user/theswizec`}</a>{`) to catch me live.`}</em></p>
    <lite-youtube {...{
      "videoid": "eOenovuDHxE",
      "videostartat": "0"
    }}></lite-youtube>
    <p><a parentName="p" {...{
        "href": "http://brendangregg.com/flamegraphs.html"
      }}>{`Flamegraphs`}</a>{` are great. They show you when you've been bad and made the CPU cry.`}</p>
    <p>{`Here is a flamegraph built with React & D3. It shows some stack data I stole from `}<a parentName="p" {...{
        "href": "https://github.com/spiermar/d3-flame-graph/blob/master/examples/stacks.json"
      }}>{`the internet`}</a>{`:`}</p>
    <div><div parentName="div" {...{
        "className": "static-tweet-embed"
      }}>{`
        `}<a parentName="div" {...{
          "className": "author",
          "href": "https://t.co/VuU1lFnIe7"
        }}><img parentName="a" {...{
            "src": "https://pbs.twimg.com/profile_images/1423736293385662466/AnF0Fsi6_normal.jpg",
            "loading": "lazy",
            "alt": "Swizec Teller writing a secret book avatar"
          }}></img><b parentName="a">{`Swizec Teller writing a secret book`}</b>{`@Swizec`}</a>{`
        `}<blockquote parentName="div">{`This is better. I'm gonna have to spend some time thinking about how to make individual nodes inspectable with a nice animated transition.`}<br parentName="blockquote"></br><br parentName="blockquote"></br>{`I assume that would be a useful feature `}<a parentName="blockquote" {...{
            "href": "https://twitter.com/brian_d_vaughn"
          }}>{`@brian_d_vaughn`}</a>{` ? `}</blockquote>{`
        `}<div parentName="div" {...{
          "className": "media"
        }}><img parentName="div" {...{
            "src": "https://pbs.twimg.com/media/DezNmBrUwAAesMv.jpg",
            "width": "100%",
            "loading": "lazy",
            "alt": "Tweet media"
          }}></img></div>{`
        `}<div parentName="div" {...{
          "className": "time"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/1003402982552002560"
          }}>{`10:28:08 PM – 6/3/2018`}</a></div>{`
        `}<div parentName="div" {...{
          "className": "stats"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/intent/like?tweet_id=1003402982552002560",
            "className": "like"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr",
              "style": {}
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z"
                }}></path></g></svg>{`2`}</a>{` `}<a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/1003402982552002560",
            "className": "reply"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"
                }}></path></g></svg>{`1`}</a></div>{`
    `}</div></div>
    <p>{`You can try it out `}<a parentName="p" {...{
        "href": "https://dist-wnnnpntytb.now.sh/"
      }}>{`here`}</a>{`, and see the `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/react-d3-flamegraph"
      }}>{`code on GitHub`}</a>{`. Consider it a work in progress. Releasing as open source once the animation works smoothly :)`}</p>
    <h2 {...{
      "id": "heres-how-it-works-"
    }}>{`Here's how it works 👇`}</h2>
    <p>{`You have two components:`}</p>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`<Flamegraph>`}</inlineCode>{`, which recursively renders the data tree`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`<FlameRect>`}</inlineCode>{`, which renders a particular rectangle and its label`}</li>
    </ol>
    <h3 {...{
      "id": "flamegraph"
    }}>{`Flamegraph`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`<Flamegraph>`}</inlineCode>{` component without animation takes your data, loops it through the array of entries at this level, and calls itself on `}<inlineCode parentName="p">{`data.children`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { scaleLinear, schemeOrRd, color as d3color } from "d3";

class Flamegraph extends React.Component {
    render() {
        const { x = 0, y = 0, width, level = 0, data } = this.props;

        const xScale = scaleLinear()
            .domain([0, data.reduce((sum, d) => sum + d.value, 0)])
            .range([0, width]);

        return (
            <g transform="{\`translate(\${x}," \${y})\`}="">
                {data.map((d, i) => {
                    const start = data
                        .slice(0, i)
                        .reduce((sum, d) => sum + d.value, 0);

                    return (
                        <react class="fragment" key={\`\${level}-\${d.name}\`}>
                            <flamerect x={xScale(start)} y={0} width={xScale(d.value)} height={RowHeight} name={d.name}>
                            {d.children && (
                                <flamegraph data={d.children} x={xScale(start)} y={RowHeight} width={xScale(d.value)} level="{level" +="" 1}="">
                            )}
                        </flamegraph></flamerect></react>
                    );
                })}
            </g>
        );
    }
}
`}</code></pre>
    <p>{`Our `}<inlineCode parentName="p">{`render`}</inlineCode>{` method takes a bunch of params out of `}<inlineCode parentName="p">{`props`}</inlineCode>{`, creates a linear D3 scale to make calculations easier, then renders an SVG grouping element.`}</p>
    <p>{`Inside that element, we loop through the `}<inlineCode parentName="p">{`data`}</inlineCode>{`, and for each entry, we create a new `}<inlineCode parentName="p">{`<React.Fragment>`}</inlineCode>{`. The fragment contains `}<inlineCode parentName="p">{`<FlameRect>`}</inlineCode>{` which represents the current datapoint, and a `}<inlineCode parentName="p">{`<Flamegraph>`}</inlineCode>{` which renders all the child nodes.`}</p>
    <p>{`We decide each element's `}<inlineCode parentName="p">{`x`}</inlineCode>{` position based on the sum of all node values up to the current one. And we make sure the child `}<inlineCode parentName="p">{`<Flamegraph>`}</inlineCode>{` uses the same width as the current node. This creates the neat stacking effect.`}</p>
    <p><img parentName="p" {...{
        "src": "https://swizec.com/blog/wp-content/uploads/2018/06/flamegraph-screenshot.png",
        "alt": null
      }}></img></p>
    <h3 {...{
      "id": ""
    }}></h3>
    <p>{`The `}<inlineCode parentName="p">{`<FlameRect>`}</inlineCode>{` component takes care of choosing a random color on initial render, highlighting when clicked, and displaying a label if there's enough space.`}</p>
    <p>{`It looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { scaleLinear, schemeOrRd, color as d3color } from "d3";

class FlameRect extends React.Component {
    state = {
        hideLabel: false,
        selected: false,
        color: schemeOrRd[9][Math.floor(Math.random() * 9)]
    };

    labelRefCallback = element => {
        if (
            element &&
            element.getBoundingClientRect().width > this.props.width
        ) {
            this.setState({
                hideLabel: true
            });
        }
    };

    onClick = () => this.setState({ selected: !this.state.selected });

    render() {
        const { x, y, width, height, name } = this.props,
            { hideLabel, selected } = this.state;
        let { color } = this.state;

        if (selected) {
            color = d3color(color).brighter();
        }

        return (
            <g transform="{\`translate(\${x}," \${y})\`}="" style={{ cursor: "pointer" }} onclick={this.onClick}>
                <rect x={0} y={0} width={width} height={height} style={{ stroke: "white", fill: color }}>
                {!hideLabel && (
                    <text x={5} y={13} style={{ fontsize: "12px" }} ref={this.labelRefCallback}>
                        {name}
                    </text>
                )}
            </rect></g>
        );
    }
}
`}</code></pre>
    <p>{`We render a grouping element that contains a `}<inlineCode parentName="p">{`<rect>`}</inlineCode>{` and a `}<inlineCode parentName="p">{`<text>`}</inlineCode>{`. The rect gets sizing and color information, and the label gets a ref callback and some text.`}</p>
    <p>{`We use the ref callback to dynamically detect the size of our label and hide it if necessary. That happens in `}<inlineCode parentName="p">{`labelRefCallback`}</inlineCode>{`.`}</p>
    <p><inlineCode parentName="p">{`onClick`}</inlineCode>{`, we flip the selected state.`}</p>
    <p>{`And well, that's it. You can think of this component as a basic toggle component. Uses all the same logic, just renders as a colorful rectangle instead of a button.`}</p>
    <h2 {...{
      "id": "fin"
    }}>{`Fin`}</h2>
    <p>{`That's the basic `}<inlineCode parentName="p">{`<Flamegraph>`}</inlineCode>{` component. Uses recursion to render a tree data structure and lets you highlight individual elements.`}</p>
    <p>{`Where it gets tricky is adding animation that lets users explore their data. Turns out deeply nested React elements are hard to animate smoothly.`}</p>
    <div><div parentName="div" {...{
        "className": "static-tweet-embed"
      }}>{`
        `}<a parentName="div" {...{
          "className": "author",
          "href": "https://t.co/VuU1lFnIe7"
        }}><img parentName="a" {...{
            "src": "https://pbs.twimg.com/profile_images/1423736293385662466/AnF0Fsi6_normal.jpg",
            "loading": "lazy",
            "alt": "Swizec Teller writing a secret book avatar"
          }}></img><b parentName="a">{`Swizec Teller writing a secret book`}</b>{`@Swizec`}</a>{`
        `}<blockquote parentName="div">{`Smoothly animating recursive structures is hard. `}<a parentName="blockquote" {...{
            "href": "https://twitter.com/brian_d_vaughn"
          }}>{`@brian_d_vaughn`}</a>{` might not get the flamegraph shininess I half promised`}<br parentName="blockquote"></br><br parentName="blockquote"></br><a parentName="blockquote" {...{
            "href": "https://twitter.com/sxywu"
          }}>{`@sxywu`}</a>{` `}<a parentName="blockquote" {...{
            "href": "https://twitter.com/micahstubbs"
          }}>{`@micahstubbs`}</a>{` any tips? `}</blockquote>{`
        `}<div parentName="div" {...{
          "className": "media"
        }}><img parentName="div" {...{
            "src": "https://pbs.twimg.com/ext_tw_video_thumb/1003903967204524033/pu/img/YSuCZB7uL3JgcljR.jpg",
            "width": "100%",
            "loading": "lazy",
            "alt": "Tweet media"
          }}></img></div>{`
        `}<div parentName="div" {...{
          "className": "time"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/1003904212755857408"
          }}>{`7:39:50 AM – 6/5/2018`}</a></div>{`
        `}<div parentName="div" {...{
          "className": "stats"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/intent/like?tweet_id=1003904212755857408",
            "className": "like"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr",
              "style": {}
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z"
                }}></path></g></svg>{`7`}</a>{` `}<a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/1003904212755857408",
            "className": "reply"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"
                }}></path></g></svg>{`3`}</a></div>{`
    `}</div></div>
    <p>{`The worst part is how long it takes before React even propagates `}<inlineCode parentName="p">{`prop`}</inlineCode>{` updates through the tree before the animation even starts. Once the animation is running, it's smooth as silk.`}</p>
    <p>{`But getting it started, ho boy.`}</p>
    <p>{`This warrants further research. I'll be back :)`}</p>
    <lite-youtube {...{
      "videoid": "MLchhOfiOwM",
      "videostartat": "0"
    }}></lite-youtube>
    <p>{`PS: version `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/react-d3-flamegraph"
      }}>{`0.1.0 is in fact on npm`}</a>{` if you want to play.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      